import { CSSProperties, useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { gql, useQuery } from '@apollo/client';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { theme } from '../theme';
// UI
import { ThemeProvider, Pane } from 'evergreen-ui'
import ServicesList from '../components/ServicesList';
import ServicesSearch from '../components/ServicesSearch';
import ServicesLabels from '../components/ServicesLabels';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

function Services() {

  const { projectId } = useParams();
  const { t } = useTranslation();
  const [userLocale, setUserLocale] = useState<string|null|undefined>(undefined);
  const location = useLocation();
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const userLocale = urlParams.get('locale');
    setUserLocale(userLocale);
  }, [location]);
  
  const domain = (window.location.hostname === 'localhost') ? 'events.byobstl.com' : window.location.hostname;
  const projects: any = {
    'events.byobstl.com': 'al3aCQ6KxPTSu3bgkb2P',
    'events-byobstl-com.web.app': 'al3aCQ6KxPTSu3bgkb2P',
  };
  let pid: string = projects[domain];
  if(projectId) pid = projectId;

  type ProjectConfig = {
    disco: any;
  };

  const projectsConfig: ProjectConfig = {
    disco: {
      iconSrc: "https://cdn.timerise.io/admin/disco-favicon.png",
      bookingPageDomain: 'booking.byobstl.com',
      logoHeight: 60,
      logoHref: '/' + pid,
      searchShow: true,
      searchInputLabel: t('search'),
      searchInputPlaceholder: t('search_placeholder'),
      labelsSelectLabel: t('label'),
      bookButtonLabel: t('book_now'),
      termsUrl: 'https://timerise.io/legal-tac-en.html',
      privacyUrl: 'https://timerise.io/legal-pp-en.html',
      poweredByLogoUrl: 'https://cdn.timerise.io/admin/disco-logo.svg',
      poweredByLogoHref: 'https://byobstl.com',
      poweredByLogoHeight: 20,
    },
  };

  const pidConfigMap = new Map<string, any>([
    ['al3aCQ6KxPTSu3bgkb2P', projectsConfig.disco],
    ['C5PcqD6BEHA7JWpSvtqh', projectsConfig.disco],
    ['UeCkFKVhylPAwz8Ek4JT', projectsConfig.disco],
    ['ugNx0oCut9wIzy6k08yM', projectsConfig.disco],
    ['X5eVvNHJGzm1u2JQ0hBj', projectsConfig.disco],
    ['UPQXS2DTbB2ywqpEuFq8', projectsConfig.disco],
    ['9jqypJnOYBYhZuYFUXKS', projectsConfig.disco],
    ['lN4CQBUmi3NkJzDId4NG', projectsConfig.disco],
    ['NA0aNwbbhqfXSA7Zvld9', projectsConfig.disco],
    ['Ey92VAKZ6lvBALdb9Eha', projectsConfig.disco],
    ['bfEsZHZfPAPO3yrmytV0', projectsConfig.disco],
    ['r4KCjezdCeJaeMEFXpmS', projectsConfig.disco],
    ['WuLgO6JdIhWlaBYphND8', projectsConfig.disco]
  ]);

  const projectConfig = pidConfigMap.get(pid) || projectsConfig.disco;

  const GET_PROJECT = gql`
    query GetProject {
      project(projectId: "${pid}") {
        defaultLocale 
        localTimeZone 
        title
        logoUrl
        coverUrl
        labels
      }
    }
  `;
  const { data } = useQuery(GET_PROJECT);

  const [searchParams, setSearchParams] = useSearchParams();

  const query = searchParams.get('query') || '';
  const label = searchParams.get('label') || '';

  const componentStyle: CSSProperties = { 
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'column',
    maxWidth: '1200px', 
    margin: 'auto', 
    padding: '12px', 
  };

  let logoUrl = data && data?.project && data?.project.logoUrl && data?.project.coverUrl ? data?.project.coverUrl : data?.project.logoUrl;
  if(!logoUrl || isEmpty(logoUrl)) logoUrl = projectConfig.iconSrc;

  return (
    <ThemeProvider value={theme}>
      {
        data && data.project && (
          <Helmet>
            <link rel="icon" type="image/png" href={projectConfig.iconSrc} />
            <title>{data.project.title} | {t('Services')}</title>
          </Helmet>
        )
      }
      <Pane style={componentStyle}>
        {data && data.project && <Header title={data.project.title} logoUrl={logoUrl} logoHref={projectConfig.logoHref} labels={data.project.labels} setSearchParams={setSearchParams} query={query} label={label} projectConfig={projectConfig} />}
        {(!query || query.length < 3) && (!label || label.length < 2) && data && data.project && <ServicesList projectId={pid} locale={data.project.defaultLocale} bookingPageDomain={projectConfig.bookingPageDomain} buttonLabel={projectConfig.bookButtonLabel} userLocale={userLocale} />}
        {query && query.length > 2 && data && data.project && <ServicesSearch projectId={pid} query={query} locale={data.project.defaultLocale} bookingPageDomain={projectConfig.bookingPageDomain} buttonLabel={projectConfig.bookButtonLabel} userLocale={userLocale} />}
        {label && label.length > 1 && data && data.project && <ServicesLabels projectId={pid} label={label} locale={data.project.defaultLocale} bookingPageDomain={projectConfig.bookingPageDomain} buttonLabel={projectConfig.bookButtonLabel} />}
        {data && data.project && <Footer title={data.project.title} logoUrl={projectConfig.poweredByLogoUrl} logoHref={projectConfig.poweredByLogoHref} logoHeight={projectConfig.poweredByLogoHeight} termsUrl={projectConfig.termsUrl} privacyUrl={projectConfig.privacyUrl} timezone={data.project.localTimeZone} locale={data.project.defaultLocale} userLocale={userLocale} />}
      </Pane>
    </ThemeProvider>
  );
}

export default Services;
