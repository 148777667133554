import React from 'react';
import { RouterProvider } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { router } from './Router';
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, HttpLink, from } from '@apollo/client';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import './index.css';
import './i18n';
import i18n from './i18n';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const headers = {
  'Accept-Language': i18n.language || 'en-US',
  'X-Api-Client-Name': 'services-app',
};

const httpLink = new HttpLink({
  uri: 'https://' + process.env.REACT_APP_API_DOMAIN + '/v1',
  headers: headers
});

const middlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: headers
  });
  return forward(operation);
});

const client = new ApolloClient({
  link: from([middlewareLink, httpLink]),
  cache: new InMemoryCache(),
});

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <RouterProvider router={router} />
    </ApolloProvider>
  </React.StrictMode>
);
