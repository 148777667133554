import { CSSProperties } from 'react';
// UI
import { Pane, Label, TextInput } from 'evergreen-ui'

function SearchBox(props: { query: string, setSearchParams: any, inputLabel: string, inputPlaceholder: string }) {

  const { query, setSearchParams, inputLabel, inputPlaceholder } = props;

  const boxStyle: CSSProperties = {
    backgroundColor: 'var(--secondary-color)',
    border: 'none',
    borderRadius: '4px',
    paddingLeft: '12px',
    height: '38px',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08)',
  };

  const labelStyle: CSSProperties = {
    fontWeight: '400',
    fontSize: '13px',
  };

  const inputStyle: CSSProperties = {
    marginTop: '1px',
    width: '100%',
    border: 'none',
    fontWeight: '600',
    fontSize: '13px',
  };

  return (
    <Pane display="flex" alignItems="center" style={boxStyle}>
      <Label style={labelStyle}>{inputLabel}:</Label>
      <TextInput placeholder={inputPlaceholder} value={query} onChange={(e: any) => setSearchParams({ query: e.target.value })} style={inputStyle} />
    </Pane>
  );

}

export default SearchBox;
