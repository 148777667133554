import { CSSProperties } from 'react';
// UI
import { Pane, Label, Select } from 'evergreen-ui'
import { t } from 'i18next';

function LabelsFilterBox(props: { labels: string[], label: string, setSearchParams: any, selectLabel: string }) {

  const { labels, label, setSearchParams, selectLabel } = props;

  const boxStyle: CSSProperties = {
    backgroundColor: 'var(--secondary-color)',
    border: 'none',
    borderRadius: '4px',
    paddingLeft: '12px',
    height: '38px',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08)',
  };

  const selectStyle: CSSProperties = {
    marginTop: '0px',
    width: '180px',
    border: 'none',
    borderRadius: '4px',
    fontWeight: '600',
    fontSize: '13px',
  };

  const labelStyle: CSSProperties = {
    fontWeight: '400',
    fontSize: '13px',
  };

  return (
    <Pane display="flex" alignItems="center" style={boxStyle}>
      <Label style={labelStyle}>{selectLabel}:</Label>
        <Select onChange={(e: any) => setSearchParams({ label: e.target.value })} value={label} style={selectStyle}>
          <option value="">{t('all')}</option>
          {labels && labels.length > 0 && labels.map((label: string, index: number) => (
          <option key={index} value={label}>{label}</option>
          ))}
      </Select>
    </Pane>
  );

}

export default LabelsFilterBox;
