import { mergeTheme, defaultTheme } from 'evergreen-ui';

export  const mediaQueries = { 
  isMobile: "screen and (max-width: 576px)",
  isTablet: "(min-width: 577px) and (max-width: 1000px)",
  isDesktop: "(min-width: 1001px)"
};

export const theme = mergeTheme(defaultTheme, {
  colors: {
    default: 'var(--primary-color)',
    danger: 'var(--red-color)',
  },
  components: {
    Spinner: {
      baseStyle: {
        color: 'var(--line-color)',
      },
    },  
    Card: {
      baseStyle: {
        borderRadius: '4px',
      },
    },
    Heading: {
      baseStyle: {
        color: 'var(--primary-color)',
      },
      sizes: {
        800: { 
          fontSize: '18px'
        },
      },
    },
    Paragraph: {},
    Text: {
      sizes: {
        300: {
          fontSize: '13px',
          fontWeight: '400',
          lineHeight: '22px',
        },
      },
    },
    Link: {
      baseStyle: {
        color: 'var(--primary-color)',
        selectors: {
          _hover: {
            color: 'var(--primary-color)',
          },
        },
      },
    },
    Label: {
      baseStyle: {
        color: 'var(--primary-color)',
        fontWeight: '400',
        fontSize: '13px',
      },
    },
    Input: {
      baseStyle: {
        borderColor: 'var(--line-color)',
        borderRadius: '4px',
        color: 'var(--primary-color)',
        fontSize: '13px',
        lineHeight: '22px',
        transition: 'none',
        marginTop: '-4px',
        selectors: {
          _placeholder: {
            color: '#999999'
          },
          _disabled: {
            cursor: 'default',
            backgroundColor: 'var(--background-color)',
            color: 'var(--primary-color)',
          }
        }
      },
      appearances: {
        default: {
          backgroundColor: 'var(--secondary-color)',
          borderColor: 'var(--line-color)',
          selectors: {
            _focus: {
              zIndex: 'zIndices.focused',
              boxShadow: 'none',
              borderColor: 'var(--primary-color)'
            },
            _invalid: {
              borderColor: 'var(--red-color)'
            },
          }
        },
      },
    },
    Select: {
      appearances: {
        default: {
          border: 'none',
          color: 'var(--primary-color)',
          fontWeight: '600',
          fontSize: '13px',
          selectors: {
            _focus: {
              boxShadow: 'none',
            },
            _hover: {
              backgroundColor: 'var(--secondary-color)',
            },
          },
        },
      },  
    },
    Button: {
      baseStyle: {
        width: '100%',
        textDecoration: 'none',
      },
      appearances: {
        default: {
          color: 'var(--primary-color)',
          backgroundColor: 'var(--secondary-color)',
          borderRadius: '4px',
          textDecoration: 'none',
          borderColor: 'var(--primary-color)',
          selectors: {
            _hover: {
              color: 'var(--secondary-color)',
              backgroundColor: 'var(--primary-color)',
              border: '1px solid var(--secondary-color)',
            },
          },
        },
        primary: {
          color: 'var(--secondary-color)',
          backgroundColor: 'var(--primary-color)',
          borderRadius: '4px',
          textDecoration: 'none',
          selectors: {
            _hover: {
              color: 'var(--primary-color)',
              backgroundColor: 'var(--secondary-color)',
              border: '1px solid var(--primary-color)',
            },
          },
        },
      },
    },
  },
});
