import React from 'react';
import { Link } from 'react-router-dom';
// UI
import { Pane } from 'evergreen-ui'

function Logo(props: { logoUrl: string, logoHref: string, title: string, height: number }) {

  const { logoUrl, logoHref, title, height } = props;

  return (
    <Pane marginRight="18px">
      <Link to={logoHref}>
        <img src={logoUrl} alt={title} title={title} style={{ maxHeight: height }} />
      </Link>
    </Pane>
  );

}

export default Logo;
