import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import ErrorPage from './ErrorPage';
import Services from './pages/Services';

/* Creating a router object that will be used by the `<Router />` component. */
export const router = createBrowserRouter([
  { path: '/', element: <Services />, errorElement: <ErrorPage />},
  { path: '/:projectId', element: <Services />, errorElement: <ErrorPage /> },
]);
