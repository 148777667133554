import { CSSProperties } from 'react';
// UI
import { Pane, Text } from 'evergreen-ui'

function EmptyList() {

  const componentStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 'calc(100vh - 128px)',
  };

  return (
    <Pane style={componentStyle}>
      <Text size={300} paddingBottom={256}>No results</Text>
    </Pane>
  );

}

export default EmptyList;
