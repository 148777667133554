import { Fragment } from 'react';
import { useRouteError, isRouteErrorResponse } from 'react-router-dom';

export default function ErrorPage() {

  const error = useRouteError();

  if(isRouteErrorResponse(error)) {

    return (
      <Fragment>
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{error.statusText || error.status}</i>
        </p>
      </Fragment>
    );

  } else if(error instanceof Error) {

    return (
      <Fragment>
        <h1>Oops! Unexpected Error</h1>
        <p>Something went wrong.</p>
        <p>
          <i>{error.message}</i>
        </p>
      </Fragment>
    );

  } else return null;

}
