import { CSSProperties } from 'react';
import { gql, useQuery } from '@apollo/client';
// UI
import { Pane } from 'evergreen-ui'
import ServiceBox from './ServiceBox';
import { ServiceInterface } from '../types';
import Loading from './Loading';
import EmptyList from './EmptyList';

function ServicesSearch(props: { projectId: string, query: string, locale: string, bookingPageDomain: string, buttonLabel: string, userLocale?: string|null|undefined }) {

  const { projectId, query, locale, bookingPageDomain, buttonLabel, userLocale } = props;

  const GET_SERVICES_SEARCH = gql`
    query GetServices {
      services(projectId: "${projectId}" query: "${query}" draft: false) {
        serviceId
        project { title } 
        locations { title address } 
        hosts { fullName } 
        serviceId 
        featured 
        title 
        shortDescription 
        durationInfo 
        price 
        currency 
        shortUrl 
        media { url } 
        draft
      }
    }
  `;
  const { loading, data } = useQuery(GET_SERVICES_SEARCH);

  const componentStyle: CSSProperties = {
    display: 'flex', 
    justifyItems: 'start', 
    justifyContent: 'start',
    flexDirection: 'row', 
    flexWrap: 'wrap',
  };

  return (
    <Pane style={componentStyle}>
      {loading && <Loading />}
      {data && data.services && data.services.length === 0 && <EmptyList />}
      {data && data.services && data.services.length > 0 && data.services.map((service: ServiceInterface) => (
        <ServiceBox key={service.serviceId} locale={locale} service={service} bookingPageDomain={bookingPageDomain} buttonLabel={buttonLabel} userLocale={userLocale} />
      ))}
    </Pane>
  );

}

export default ServicesSearch;
