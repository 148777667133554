import { CSSProperties } from 'react';
import { useMediaQuery } from 'react-responsive';
import ReactMarkdown from 'react-markdown'
// UI
import { Pane, Card, Heading, Text, Link, Button } from 'evergreen-ui'
import { ServiceInterface } from '../types';
import { mediaQueries } from '../theme';

function ServiceBox(props: { locale: string, service: ServiceInterface, bookingPageDomain: string, buttonLabel: string, userLocale?: string|null|undefined }) {

  const { serviceId, media, title, shortDescription, locations, hosts, durationInfo, price, currency } = props.service;

  const { locale, userLocale, buttonLabel } = props;
  const bookingPageDomain = props.bookingPageDomain;
  const bookingPageUrl: string = 'https://'+  bookingPageDomain + '/service/' + serviceId;

  const isMobile = useMediaQuery({ query: mediaQueries.isMobile });
  const isTablet = useMediaQuery({ query: mediaQueries.isTablet });

  const boxStyle: CSSProperties = { 
    width: isMobile ? 'calc(100% - 20px)' : isTablet ? 'calc((100% / 2) - 20px)' : 'calc((100% / 3) - 20px)',
    backgroundColor: 'var(--secondary-color)',
    border: 'none',
    borderRadius: '4px',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08)',
  };

  const headingStyle: CSSProperties = {
    fontWeight: '700',
    lineHeight: '24px',
  };

  const iconStyle: CSSProperties = { 
    marginRight: '4px' 
  };

  const iconTitleStyle: CSSProperties = { 
    fontWeight: '700' 
  };

  const localPrice = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  });

  const mediaUrl = media[0] ? media[0].url : 'https://cdn.timerise.io/app/placeholder-light.png';

  return (
    <Card style={boxStyle} display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" padding="20px" margin="10px">
      <Pane>
        <Pane marginBottom="12px">
          <img src={mediaUrl} alt={title} style={{ width: '100%', aspectRatio: '4 / 3', objectFit: 'cover', borderRadius: '4px' }} />
        </Pane>
        <Pane marginBottom="8px">
          <Heading is="h2" size={800} style={headingStyle}>{title}</Heading>
        </Pane>
        {shortDescription && (
        <Pane marginBottom="12px">
          <Text size={300}>
            <ReactMarkdown>{shortDescription}</ReactMarkdown>
          </Text>
        </Pane>
        )}
        {locations && locations[0] && (
        <Pane display="flex" flexDirection="row" alignItems="start" marginTop="8px">
          <img src="https://cdn.timerise.io/app/info-address.png" alt="Host" width={16} style={iconStyle} />
          <div>
            <Text style={iconTitleStyle}>{locations[0].title}</Text>
          </div>
        </Pane>
        )}
        {hosts && hosts[0] && (
        <Pane display="flex" flexDirection="row" alignItems="center" marginTop="8px">
          <img src="https://cdn.timerise.io/app/info-host.png" alt="Host" width={16} style={iconStyle} />
          <div>
            <Text style={iconTitleStyle}>{hosts[0].fullName}</Text>
          </div>
        </Pane>
        )}
        {durationInfo && (
        <Pane display="flex" flexDirection="row" alignItems="center"  marginTop="8px">
          <img src="https://cdn.timerise.io/app/info-duration.png" alt="Duration" width={16} style={iconStyle} />
          <div>
            <Text style={iconTitleStyle}>{durationInfo}</Text>
          </div>
        </Pane>
        )}
        {!!price && (price > 0) && (
        <Pane display="flex" flexDirection="row" alignItems="center"  marginTop="8px">
          <img src="https://cdn.timerise.io/app/info-price.png" alt="Price" width={16} style={iconStyle} />
          <div>
            <Text style={iconTitleStyle}>{localPrice.format(price)}</Text>
          </div>
        </Pane>
        )}
      </Pane>
      <Pane width="100%">
        <Button is={Link} href={bookingPageUrl + (userLocale ? '?locale=' + userLocale : '')} height={40} marginTop="18px" style={{ fontWeight: 700 }}>{buttonLabel}</Button>
      </Pane>
    </Card>
  );

}

export default ServiceBox;
