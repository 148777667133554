import { CSSProperties } from 'react';
// UI
import { Pane, Spinner } from 'evergreen-ui'

function Loading() {

  const componentStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 'calc(100vh - 128px)',
  };

  return (
    <Pane style={componentStyle}>
      <Spinner paddingBottom={256} />
    </Pane>
  );

}

export default Loading;
