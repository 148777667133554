import { CSSProperties } from 'react';
import { mediaQueries } from '../theme';
import { useMediaQuery } from 'react-responsive';
// UI
import { Pane, Text, Link } from 'evergreen-ui';
import { useTranslation } from 'react-i18next';

export default function Footer(props: { 
    title: string, 
    privacyUrl: string, 
    termsUrl: string, 
    logoUrl: string, 
    logoHref: string,
    logoHeight: number, 
    timezone: string, 
    locale: string, 
    userLocale?: string|null|undefined 
  }) {

  const { title, logoUrl, logoHref, logoHeight, privacyUrl, termsUrl, timezone, locale, userLocale } = props;

  const isMobile = useMediaQuery({ query: mediaQueries.isMobile });
  const isTablet = useMediaQuery({ query: mediaQueries.isTablet });

  const { t } = useTranslation();

  const componentStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '12px',
    marginTop: '18px',
  };

  const menuStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: (isMobile || isTablet) ? 'column' : 'row',
    textAlign: 'left',
  };

  const textStyle: CSSProperties = {
    fontSize: '11px',
    marginRight: (isMobile || isTablet) ? 'auto' : '10px',
  };

  return (
    <footer style={componentStyle}>
      <Pane style={menuStyle}>
        <Text style={textStyle} marginBottom={10}>{title} © 2024</Text>
        <Link style={textStyle} marginBottom={5} href={privacyUrl}>{t('pp')}</Link>
        <Link style={textStyle} marginBottom={10} href={termsUrl}>{t('t_and_c')}</Link>
        <Text style={textStyle} marginBottom={5}>{t('timezone')}: {timezone}</Text>
        <Text style={textStyle} marginBottom={5}>{t('locale')}: {userLocale ? userLocale : locale}</Text>
      </Pane>
      <Pane>
        <Link href={logoHref} target="_blank">
          <img src={logoUrl} height={logoHeight} alt={title} />
        </Link>
      </Pane>
    </footer>
  );

}
