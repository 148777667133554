import { CSSProperties } from 'react';
import { mediaQueries } from '../theme';
import { useMediaQuery } from 'react-responsive';
// UI
import { Pane } from 'evergreen-ui'
import Logo from './Logo';
import LabelsFilterBox from './LabelsFilterBox';
import SearchBox from './SearchBox';

function Header(props: { title: string, logoUrl: string, logoHref: string, labels: string[], setSearchParams: any, query: string, label: string, projectConfig: any }) {

  const { logoUrl, logoHref, title, labels, query, label, setSearchParams, projectConfig } = props;

  const isMobile = useMediaQuery({ query: mediaQueries.isMobile });

  const componentStyle: CSSProperties = {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: isMobile ? 'start' : 'center',
    justifyContent: 'space-between',
    gap: '10px',
    margin: '10px',
    padding: '30px 0px',
  };

  const formStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: isMobile ? 'column' : 'row',
    textAlign: 'left',
    gap: '10px',
    width: isMobile ? '100%' : 'auto',
  };

  return (
    <Pane style={componentStyle}>
      <Logo logoUrl={logoUrl} logoHref={logoHref} title={title} height={projectConfig.logoHeight} />
      {projectConfig.searchShow && projectConfig.searchShow === true && (
      <Pane style={formStyle}>
        <SearchBox query={query} setSearchParams={setSearchParams} inputLabel={projectConfig.searchInputLabel} inputPlaceholder={projectConfig.searchInputPlaceholder} />
        {labels && labels.length > 0 && (
        <LabelsFilterBox labels={labels} label={label} setSearchParams={setSearchParams} selectLabel={projectConfig.labelsSelectLabel} />
        )}
      </Pane>
      )}
    </Pane>
  );

}

export default Header;
